import React from "react"

// Components
import Seo from "components/seo"
import Hero from "../../components/hero"

// Page sections
import List from "../screens/resources/viewpoints/list"

const Viewpoints = () => {
  return (
    <React.Fragment>
      <Hero pageTitle="Viewpoints">
        <p>A digest of interesting articles and content on topics that relate to the fascinating world of infectious disease and rapid testing.</p>
      </Hero>
      <List />
    </React.Fragment>
  )
}

export default Viewpoints

export const Head = () => (
  <Seo
        title="Viewpoints"
        description="A digest of interesting articles and content on topics that relate to the fascinating world of infectious disease and rapid testing."
        image="/meta/viewpoints.jpg"
  />
)
